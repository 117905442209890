import { useTranslation } from 'react-i18next';
import Contact from '../components/forms/Contact';
import CardCourse from '../components/CardCourse';
import CardOpinion from '../components/CardOpinion';
import Footer from '../components/tools/Footer';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';

const Home = () => {
    const { t, i18n } = useTranslation(['pages\\homeTr']);
    const navigate = useNavigate();

    let opinions_url = process.env.REACT_APP_OPINIONS;
    if (window.innerWidth < 1200) {
        opinions_url = process.env.REACT_APP_OPINIONS_SMARTPHONE;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='home'>
            <div className="img-background">

                <div className="titles">
                    <p className="title">
                        {t('title.part1')} <span>{t('title.part2')}</span>
                    </p>
                    <p className="sub-title">
                        {t('sub-title')}
                    </p>
                    <p className="description">
                        {t('description')}
                    </p>
                </div>
                <SocialNetworksMainBackground/>
            </div>
            <div className="container">
                <div className="surf-school">
                    <p className="title title-blue-global">
                        {t('surf-school.title')}
                    </p>
                    <p className='description description-global'>
                        {t('surf-school.description')}
                    </p>
                </div>
            </div>
            <div className="coach-home">
                <div className="coach-img">
                    <img src="img/pages/home/coach-responsive.webp" alt="" />
                </div>
                <div className="container">
                    <div className="block-right">
                        <div className="title title-blue-global">
                            {t('coach.title')}
                        </div>
                        <div className="description description-global">
                            <p>
                                {t('coach.description')}
                            </p>
                        </div>
                        <div className="btn-more">
                            <button onClick={() => navigate('/coach')} className='btn-blue'>
                                {t('coach.more')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="courses container">
                <div className="title-blue-global text-center">
                    <p>
                        {t('course.title')}
                    </p>
                </div>
                <div className="cards">
                    <div className="card">
                        <CardCourse
                            listenerColor='red'
                            listenerImg='surfboard.webp'
                            listenerTitle={t('course.classic')} />
                    </div>
                    <div className="card">
                        <CardCourse
                            listenerColor='blue'
                            listenerImg='surf-blue.webp'
                            listenerTitle={t('course.improvement')} />
                    </div>
                    <div className="card">
                        <CardCourse
                            listenerColor='red'
                            listenerImg='van.webp'
                            listenerTitle={t('course.pick-up')} />
                    </div>
                </div>
            </div>

            <div className="surf-stuffs">
                <div className="surf-block container">
                    <div className="img-surf">
                        <img src="img/pages/home/surf-camp.webp" alt="surf camp" />
                    </div>
                    <div className="block">
                        <div className="title title-global">
                            <p>
                                {t('surf-camp.title')}
                            </p>
                        </div>
                        <div className="description">
                            <p>
                                {t('surf-camp.description')}
                            </p>
                        </div>
                        <button onClick={() => navigate('/camp')} className="btn-transparent">
                            {t('surf-camp.more')}
                        </button>
                    </div>
                </div>
                <div className="surf-block surf-block-reverse container">
                    <div className="img-surf">
                        <img src="img/pages/home/surf-trip.webp" alt="surf trip" />
                    </div>
                    <div className="block">
                        <div className="title title-global">
                            <p>
                                {t('surf-trip.title')}
                            </p>
                        </div>
                        <div className="description">
                            <p>
                                {t('surf-trip.description.part1')}
                            </p>
                            <p>
                                {t('surf-trip.description.part2')}
                            </p>
                        </div>
                        <button onClick={() => navigate('/trips')} className="btn-transparent">
                            {t('surf-trip.more')}
                        </button>
                    </div>
                </div>
            </div>

            <div className="gallery-home container">
                <div className="title-blue-global text-center">
                    <p>
                        {t('gallery.title')}
                    </p>
                </div>
                <div className="pictures">

                    <img className='img-square' src="img/pages/gallery/galery-square-1.webp" alt="Surfeur" />
                    <img className='img-square' src="img/pages/gallery/galery-square-2.webp" alt="Surfeurs" />
                    <img className='img-square' src="img/pages/gallery/galery-square-3.webp" alt="Surfeurs" />
                    <img className='img-square' src="img/pages/gallery/galery-square-4.webp" alt="Surfeur & plage" />
                    <img className='img-square' src="img/pages/gallery/galery-square-5.webp" alt="Patrick surfeur" />
                    <img className='img-square' src="img/pages/gallery/galery-square-6.webp" alt="Surfeurs sur planche" />

                </div>
                <div className="more">
                    <button onClick={() => navigate('/gallery')} className="btn-blue">
                        {t('gallery.more')}
                    </button>
                </div>
            </div>

            <Contact listenerIsContact={false} />

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6492.147487299758!2d-1.4970388490979951!3d43.54564785160031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd514185f9e0c2a9%3A0x50693be3723ba311!2s3%20Rue%20H%C3%A9l%C3%A8ne%20Boucher%2C%2040220%20Tarnos!5e0!3m2!1sfr!2sfr!4v1653078172699!5m2!1sfr!2sfr" width="600" height="450" style={{ "border": "0" }} loading="lazy" ></iframe>

            <div className="opinions container">
                <div className="title-blue-global text-center">
                    <p>
                        {t('opinions.title')}
                    </p>
                </div>
                <div className="opinions-card">
                    <div className="card">
                        <CardOpinion listenerName={t('opinions.cards.one.name')} listenerComment={t('opinions.cards.one.comment')} listenerPicture={t('opinions.cards.one.picture')} />
                    </div>
                    <div className="card">
                        <CardOpinion listenerName={t('opinions.cards.two.name')} listenerComment={t('opinions.cards.two.comment')} listenerPicture={t('opinions.cards.two.picture')} />
                    </div>
                    <div className="card">
                        <CardOpinion listenerName={t('opinions.cards.three.name')} listenerComment={t('opinions.cards.three.comment')} listenerPicture={t('opinions.cards.three.picture')} />
                    </div>
                </div>
                <div className="more text-center">
                    <a onClick={() => window.open(opinions_url)}>
                        <button className="btn-blue">
                            {t('opinions.more')}
                        </button>
                    </a>
                </div>
            </div>


            <Footer />
        </div>
    );
};

export default Home;