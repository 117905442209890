import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import './i18n';
import { Suspense, useEffect } from 'react';
import Cookies from './components/tools/Cookies';
import Navbar from './components/tools/Navbar';
import Coach from './pages/Coach';
import ValuesAndPartners from './pages/ValuesAndPartners';
import SurfCourses from './pages/SurfCourses';
import SurfTrips from './pages/SurfTrips';
import SurfCamp from './pages/SurfCamp';
import Location from './pages/Location';
import Gallery from './pages/Gallery';
import Contacts from './pages/Contacts';
import Booking from './pages/Booking';
import LegalNotice from './pages/LegalNotice';
import CGU from './pages/CGU';
import CGV from './pages/CGV';

// loading component for suspense fallback
const Loader = () => (
  <div>
    <div>Loading</div>
  </div>
);


function App() {

  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter basename="/">
        <Navbar />
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="coach" element={<Coach />} />
          <Route path="values" element={<ValuesAndPartners />} />
          <Route path="cours" element={<SurfCourses />} />
          <Route path="trips" element={<SurfTrips />} />
          <Route path="camp" element={<SurfCamp />} />
          <Route path="location" element={<Location />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="booking" element={<Booking />} />
          <Route path="legal-notice" element={<LegalNotice />} />
          <Route path="cgv" element={<CGV />} />
          <Route path="cgu" element={<CGU />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
