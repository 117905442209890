import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../components/tools/Footer';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';

const ValuesAndPartners = () => {

    const { t, i18n } = useTranslation(['pages\\valuesAndPartnersTr']);

    useEffect(() => {

        if (window.location.href.includes("#eco-responsable")) {
            document.getElementById('eco-responsable')?.scrollIntoView();
        } else {
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <div className='values'>
            <div className="img-background">
                <div className="main-title">
                    <span className="title">
                        {t('title')}
                    </span>
                </div>

                <SocialNetworksMainBackground/>
            </div>
            <div className="container">
                <div className="values-sentence">
                    <span>
                        {t('mindset')}
                    </span>
                </div>
            </div>
            <div className="values-history">

                <div className="block container">

                    <div className="block-img">
                        <img src="img/pages/valuesAndPartners/2021.webp" alt="Recyclage équipement de surf" />
                    </div>

                    <div className="block-text">
                        <p className='title-global'>
                            {t('values.one.title')}
                        </p>
                        <p className='description-global'>
                            {t('values.one.description')}
                        </p>
                    </div>

                </div>

                <div className="block reverse container">

                    <div className="block-img">
                        <img src="img/pages/valuesAndPartners/label-tourisme-durable.webp" alt="Label tourisme durable" />
                    </div>

                    <div className="block-text">
                        <p className='title-global'>
                            {t('values.two.title')}
                        </p>
                        <p className='description-global'>
                            {t('values.two.description.part1')}
                        </p>
                        <p className='description-global'>
                            {t('values.two.description.part2')}
                        </p>
                    </div>
                </div>

                <div className="block container">

                    <div className="block-img">
                        <img src="img/pages/valuesAndPartners/tarnos.webp" alt="Plage de tarnos" />
                    </div>

                    <div className="block-text">
                        <p className='title-global'>
                            {t('values.three.title')}
                        </p>
                        <p className='description-global'>
                            {t('values.three.description')}
                        </p>
                    </div>
                </div>

            </div>

            <div className="partners container
            ">
                <div className="title-blue-global">
                    <p>
                        {t('partners.title')}
                    </p>
                </div>
                <div className="block">
                    <div className="block-img">
                        <a onClick={() => window.open("https://zeussurfboards.com/")}>
                            <img src="img/pages/valuesAndPartners/zeus.webp" alt="Zeus logo" />
                        </a>
                    </div>
                    <div className="block-text">
                        <div className="title-group">
                            <a onClick={() => window.open("https://zeussurfboards.com/")}>
                                <img src="img/pages/valuesAndPartners/zeus.webp" alt="Zeus logo" />
                            </a>
                            <div className="sub-title">
                                {t('partners.zeus.title')}
                            </div>
                        </div>
                        <div className="description-global">
                            <p className='sub-title'>{t('partners.zeus.sub-title')}</p>
                            <p>{t('partners.zeus.description')}</p>
                        </div>
                    </div>
                </div>
                <div className="block">
                    <div className="block-img">
                        <a onClick={() => window.open("https://surfwear.sooruz.com/")}>
                            <img src="img/pages/valuesAndPartners/sooruz.webp" alt="Sooruz logo" />
                        </a>
                    </div>
                    <div className="block-text">
                        <div className="title-group">
                            <a onClick={() => window.open("https://surfwear.sooruz.com/")}>
                                <img src="img/pages/valuesAndPartners/sooruz.webp" alt="Sooruz logo" />
                            </a>
                            <div className="sub-title">
                                {t('partners.sooruz.title')}
                            </div>
                        </div>
                        <div className="description-global">
                            <p className='sub-title'>{t('partners.sooruz.sub-title')}</p>
                            <p>{t('partners.sooruz.description')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-eco" id='eco-responsable'>
                <div className="container">
                    <div className="title-global text-center">
                        {t('site-eco.title')}
                    </div>
                    <div className="description-global text-center">
                        <p>
                            {t('site-eco.description.part1')}
                        </p>
                        <p>
                            {t('site-eco.description.part2')}
                        </p>
                        <p>
                            {t('site-eco.description.part3')} <a onClick={() => window.open("https://www.greenit.fr/definition/")}>Green IT</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="partners-logo container">
                <div className="title-blue-global">
                    <p>
                        {t('partners-logo.title')}
                    </p>
                </div>
                <div className="logos">
                    <div className="part1">
                        <div className="logo">
                            <a onClick={() => window.open("https://zeussurfboards.com/")}>
                                <img src="img/pages/valuesAndPartners/zeus.webp" alt="Zeus logo" />
                            </a>
                        </div>
                        <div className="logo">
                            <a onClick={() => window.open("https://www.ecosafesurfing.com/")}>
                                <img src="img/pages/valuesAndPartners/eco-safe.webp" alt="Eco safe surfing logo" />
                            </a>
                        </div>
                        <div className="logo">
                            <a onClick={() => window.open("https://www.seignanx.com/")}>
                                <img src="img/pages/valuesAndPartners/Logo-Seignanx.webp" alt="Seignanx logo" />
                            </a>
                        </div>
                    </div>
                    <div className="part1">
                        <div className="logo">
                            <a onClick={() => window.open("https://surfinsertion.com/")}>
                                <img src="img/pages/valuesAndPartners/logo-surf-insertion.webp" alt="Surf insertion logo" />
                            </a>
                        </div>
                        <div className="logo">
                            <a onClick={() => window.open("https://surfwear.sooruz.com/")}>
                                <img src="img/pages/valuesAndPartners/sooruz.webp" alt="Sooruz logo" />
                            </a>
                        </div>
                        <div className="logo">
                            <a onClick={() => window.open("https://www.tourisme-durable.org/")}>
                                <img src="img/pages/valuesAndPartners/label-tourisme-durable.webp" alt="Label tourisme durable" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default ValuesAndPartners;