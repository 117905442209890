import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../components/tools/Footer';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';

const LegalNotice = () => {
    const { t, i18n } = useTranslation(['pages\\noticeLegalTr']);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='legal-notice'>
            <div className="img-background">
                <div className="main-title">
                    <span className="title">
                        Mentions légales
                    </span>
                </div>

                <SocialNetworksMainBackground />
            </div>
            <div className="container">
                <p className='title-global'>Édition du site web</p>




                <p className="sub-title">Propriétaire : </p>
                <div className='description-global'>
                    <p>Le présent site web, accessible à partir de l’adresse URL <a onClick={() => window.open("https://surfperspective.com")}>https://surfperspective.com</a>, est la propriété de Patrick Cedras.</p>
                    <p>Mail : <a href="mailto:patced40@hotmail.fr">patced40@hotmail.fr</a></p>
                    <p>Téléphone : <a href="tel:+33647545214">+33 (0) 6 47 54 52 14</a></p>
                </div>

                <p className="sub-title">Coopérative : </p>
                <div className='description-global'>
                    <p>Activité portée par la SCIC Interstices Sud Aquitaine</p>
                    <p>Numéro de TVA Intracommunautaire : FR02448220830</p>
                    <p>Adresse : 3 Rue Hélène Boucher 40220 Tarnos.</p>
                    <p>Site : <a onClick={() => window.open("www.interstices-sud-aquitaine.fr")}>www.interstices-sud-aquitaine.fr</a></p>
                    <p>Mail : <a href='mailto:contact@interstices-sud-aquitaine.fr'>contact@interstices-sud-aquitaine.fr</a></p>
                    <p>Téléphone : <a href="tel:+33647545214">+33 (0) 5 59 74 84 10</a></p>
                </div>

                <p className='title-global'>Hébergement</p>
                <div className='description-global'>
                    <p>Le présent site web est hébergé par la société OVH.</p>
                    <p>Siège social : 2 rue Kellermann - 59100 Roubaix, France</p>
                    <p>Téléphone : <a href="tel:+33899701761">+33 (0) 8 99 70 17 61</a></p>
                    <p>Site : <a onClick={() => window.open("https://ovh.com")}>https://ovh.com</a></p>
                </div>
                <p className='title-global'>Création et réalisation</p>
                <div className='description-global'>
                    <p>Le présent site est une création Manipogo</p>
                    <p>Site : <a onClick={() => window.open("https://manipogo.fr")}>https://manipogo.fr</a></p>
                </div>



            </div>
            <Footer />
        </div>
    );
};

export default LegalNotice;