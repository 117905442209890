import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../components/tools/Footer';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';

const Gallery = () => {
    const { t, i18n } = useTranslation(['pages\\galleryTr']);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [cptViewMore, setCptViewMore] = useState<number>(0);
    const [block2, setBlock2] = useState('block-none');
    const [block3, setBlock3] = useState('block-none');
    const [block4, setBlock4] = useState('block-none');
    const [btnViewMore, setBtnViewMore] = useState('');

    const displayImages = () => {
        switch (cptViewMore) {
            case 0:
                setCptViewMore(cptViewMore + 1);
                setBlock2('');
                break;
            case 1:
                setCptViewMore(cptViewMore + 1);
                setBlock3('');
                break;
            case 2:
                setCptViewMore(cptViewMore + 1);
                setBlock4('');
                setBtnViewMore('block-none');
                break;
        }
    }

    return (
        <div className='gallery'>
            <div className="img-background">
                <div className="main-title">
                    <span className="title">
                        {t('title')}
                    </span>
                </div>

                <SocialNetworksMainBackground/>
            </div>
            <div className="color-back">
                <div className="container">
                    <div className="">
                        <img className='img-square' src="img/pages/gallery/galery-square-1.webp" alt="Surfeur" />
                        <img className='img-square' src="img/pages/gallery/galery-square-2.webp" alt="Surfeurs" />
                        <img className='img-square' src="img/pages/gallery/galery-square-3.webp" alt="Surfeurs" />
                        <img className='img-square' src="img/pages/gallery/galery-square-4.webp" alt="Surfeur & plage" />
                        <img className='img-rect' src="img/pages/gallery/galery-rect-1.webp" alt="plage et planche de surf" />
                    </div>
                    <div className={block2}>
                        <img className='img-rect' src="img/pages/gallery/galery-rect-2.webp" alt="Surfeur sur vague" />
                        <img className='img-square' src="img/pages/gallery/galery-square-5.webp" alt="Patrick surfeur" />
                        <img className='img-square' src="img/pages/gallery/galery-square-6.webp" alt="Surfeurs sur planche" />
                        <img className='img-square' src="img/pages/gallery/galery-square-7.webp" alt="Surfeurs sur plage avec planche" />
                        <img className='img-square' src="img/pages/gallery/galery-square-8.webp" alt="Surfeuse debout sur une vague" />
                    </div>
                    <div className={block3}>
                        <img className='img-rect' src="img/pages/gallery/galery-rect-3.webp" alt="2 surfeur sur la plage" />
                        <img className='img-square' src="img/pages/gallery/galery-square-9.webp" alt="Surfeur chute sur une vague" />
                        <img className='img-square' src="img/pages/gallery/galery-square-10.webp" alt="Surfeurs sur la plage" />
                        <img className='img-rect' src="img/pages/gallery/galery-rect-4.webp" alt="Couché de soleil pays basque" />
                    </div>
                    <div className={block4}>
                        <img className='img-square' src="img/pages/gallery/galery-square-11.webp" alt="Surfeurs en combinaison sur le sable" />
                        <img className='img-square' src="img/pages/gallery/galery-square-12.webp" alt="Surfeur dans une vague" />
                        <img className='img-square' src="img/pages/gallery/galery-square-13.webp" alt="Ancien bunker sur une plage" />
                        <img className='img-square' src="img/pages/gallery/galery-square-14.webp" alt="Surfeur sur une vague" />
                        <img className='img-rect' src="img/pages/gallery/galery-rect-5.webp" alt="Surfeurs en ligne avec leur planche" />
                    </div>
                    <div className={"div-btn-more " + btnViewMore}>
                        <button className="btn-blue" onClick={displayImages}>
                            <span>
                                {t('more')}
                            </span>
                            <img src="img/pages/gallery/arrow-down.svg" alt="+" />
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Gallery;