import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CardOpinion from '../components/CardOpinion';
import Footer from '../components/tools/Footer';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';

const Coach = () => {
    const { t, i18n } = useTranslation(['pages\\coachTr']);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let opinions_url = process.env.REACT_APP_OPINIONS;
    if(window.innerWidth<1200){
        opinions_url = process.env.REACT_APP_OPINIONS_SMARTPHONE;
    }

    return (
        <div className='coach'>
            <div className="img-background">
                <div className="main-title">
                    <span className="title">
                        {t('title')}
                    </span>
                </div>
                <SocialNetworksMainBackground/>
            </div>
            <div className="container blocks">
                <div className="img-coach">
                    <img src="img/pages/coach/coach.webp" alt="Coach" />
                </div>
                <div className="description-coach">
                    <div className="title-blue-global">
                        {t('coach.title')}
                    </div>
                    <div className="description-global">
                        {t('coach.description.part1')}
                    </div>
                    <div className="description-global">
                        {t('coach.description.part2')}
                    </div>
                    <div className="description-global">
                        {t('coach.description.part3')}
                    </div>
                </div>
            </div>
            <div className="container opinions">
                <div className="title-blue-global text-center">
                    <p>{t('opinions.title')}</p>
                </div>
                <div className="sub-title-blue-global text-center">
                    <p>{t('opinions.sub-title')}</p>
                </div>
                <div className="opinions-card">
                    <div className="card">
                        <CardOpinion listenerName={t('opinions.cards.one.name')} listenerComment={t('opinions.cards.one.comment')} listenerPicture={t('opinions.cards.one.picture')} />
                    </div>
                    <div className="card">
                        <CardOpinion listenerName={t('opinions.cards.two.name')} listenerComment={t('opinions.cards.two.comment')} listenerPicture={t('opinions.cards.two.picture')} />
                    </div>
                    <div className="card">
                        <CardOpinion listenerName={t('opinions.cards.three.name')} listenerComment={t('opinions.cards.three.comment')} listenerPicture={t('opinions.cards.three.picture')} />
                    </div>
                </div>
                <div className="more text-center">
                    <a onClick={() => window.open(opinions_url)}>
                        <button className="btn-blue">
                            {t('opinions.more')}
                        </button>
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Coach;