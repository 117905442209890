import React from 'react';

const SocialNetworksMainBackground = () => {
    return (
        <div className="social-networks-main-background">
            <a onClick={() => window.open(process.env.REACT_APP_FACEBOOK)} >
                <div>
                    <img src="img/components/tools/facebook.svg" alt="Facebook" />
                </div>
            </a>
            <a onClick={() => window.open(process.env.REACT_APP_INSTAGRAM)} >
                <div>
                    <img src="img/components/tools/instagram.svg" alt="Instagram" />
                </div>
            </a>
            <a onClick={() => window.open(process.env.REACT_APP_MAPS)}>
                <div>
                    <img src="img/components/tools/maps.svg" alt="Maps" />
                </div>
            </a>
        </div>
    );
};

export default SocialNetworksMainBackground;