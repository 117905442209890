import React, { useEffect } from 'react';
import Footer from '../components/tools/Footer';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';

const CGV = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='legal-notice'>
            <div className="img-background">
                <div className="main-title">
                    <span className="title title-cvg">
                        Conditions Générales de Vente
                    </span>
                </div>
                <SocialNetworksMainBackground/>
            </div>
            <div className="container">
                <p className="title-global">
                    Préambule
                </p>
                <div className='description-global'>
                    <p className="content-description-cvg">
                        L'activité d'école de surf est désignée ci-après "Surf Perspective" ou "école de surf". Elle est portée par la "SCIC INTERSTICES
                        SUD AQUITAINE" enregistrée sous le numéro 448 220 830 00039RCS Dax Code APE 8299K.
                    </p>
                </div>
                <p className='title-global'>Art.1 : Inscription :</p>
                <div className='description-global'>
                    <p>Toute inscription à un cours de surf ou à une prestation club dans notre structure se fait à partir du formulaire d’inscription papier ou en
                        ligne mis à disposition. Celui-ci devra être dûment complété par les parents ou le tuteur légal s’il il s’agit d’un mineur, ou par la personne
                        concernée s’il s’agit d’une personne majeure.</p>
                    <p>Le règlement de la prestation se fera en espèce, par virement ou chèque. Un acompte de 30% du montant total sera demandé pour
                        valider la réservation. Le solde sera à régler au plus tard avant le premier cours.</p>
                </div>
                <p className='title-global'>Art.2 : Conditions de participation :</p>
                <div className='description-global'>
                    <p>Pour toute souscription d'activité, les participants ou leurs représentants légaux affirment remplir ou que le mineur duquel ils ont la
                        charge remplit les conditions suivantes :</p>
                    <p>- Ne présenter aucune contre-indication à la pratique du surf,</p>
                    <p>- Savoir nager plus de 25m en mer sans avoir pieds (ou équivalent piscine), pouvoir effectuer 2 passages consécutifs sous une planche en
                        faisant une apnée (ou équivalent en piscine) et émerger sans signe de panique</p>
                </div>
                <p className='title-global'>Art.3 : Annulation des cours :</p>
                <div className='description-global'>
                    <p>En cas de mauvaises conditions météorologiques (mer trop forte, orage...), les cours qui ne pourront être effectués dans le cadre de la
                        prestation souscrite seront, en accord avec les élèves ou leurs responsables :</p>
                    <p>- Remplacés par une activité de substitution (technique surf, bodyboard, jeux aquatiques, découverte du milieu, du matériel,
                        sensibilisation à l’écologie, cours théoriques, visite atelier de shape…),</p>
                    <p>- Reportés à une date ultérieure dans la possibilité des plannings de l’école et des disponibilités de l’élève,</p>
                    <p>- Transformés en avoir valable dans notre structure,</p>
                    <p>- Rembourses en dernier recours</p>
                </div>

                <div className='description-global'>
                    <p>
                        Modalités de remboursement d’acompte Toute annulation intervenant :</p>
                    <p>- Un mois avant la prestation : totalité de l’acompte remboursé,</p>
                    <p>- Entre 1 mois et 15 jours avant la prestation : la moitié de l’acompte remboursé,</p>
                    <p>- Moins de 15 jours avant la prestation : l’acompte ne sera pas remboursé.</p>
                    <p>Les cours non effectués du fait de l’élève ne seront pas remboursés.</p>
                </div>


                <p className='title-global'>Art. 4 : Responsabilité :</p>
                <div className='description-global'>
                    <p>Les élèves sont pris en charge par la structure et donc sous la responsabilité des moniteurs durant les horaires de cours définis et
                        communiqués à l’avance.</p>
                    <p>Les élèves sont à nouveau sous la responsabilité de leurs parents ou tuteurs à la fin du créneau horaire. La responsabilité civile de la
                        structure cesse en dehors de ce cadre. Les parents ou responsables ont obligation de s’assurer que les cours ont bien lieu avant de laisser
                        leurs enfants dans notre structure.</p>
                    <p>Les participants déclarent bénéficier d’une assurance individuelle accident et d’une assurance individuelle rapatriement.</p>
                    <p>L’École de surf Surf Perspective décline toute responsabilité en cas de pertes, vols ou détériorations des objets
                        qui pourraient lui être confiés. Chaque personne inscrite est responsable du matériel de l'école durant l'activité en cas de perte ou de vol.</p>
                </div>
                <p className='title-global'>Art. 5 : Assurance :</p>
                <div className='description-global'>
                    <p>Les participants ou leurs responsables attestent avoir pris connaissance des garanties d’assurance en responsabilité civile souscrite
                        par Surf Perspective (dont les modalités sont décrites dans le contrat mis à disposition par la structure) et avoir
                        été informés quant à leur intérêt à souscrire des garanties d’assurances complémentaires en cas de dommages
                        corporels survenant au cours des activités organisées par Surf Perspective</p>
                </div>
                <p className='title-global'>Art. 6 : Droit à l’image :</p>
                <div className='description-global'>
                    <p>En souscrivant aux prestations organisées par l’école de surf Surf Perspective, vous autorisez cette dernière à
                        utiliser les photos et films de votre image réalisés pendant votre stage, pour la production de tout document
                        publicitaire ou toute plateforme associée à son activité commerciale et ce sans contrepartie financière.</p>
                    <p>En cas de refus de cession du droit à l’image, merci d’envoyer votre requête par courrier à SCIC Insterstices Sud Aquitaine/Surf Perspective</p>
                    <p>– Pole de services et technologique Jean Bertin – 3 rue Hélène Boucher 40220 Tarnos.</p>
                </div>
            </div>
            <Footer />
        </div >
    );
};

export default CGV;