import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Contact from '../components/forms/Contact';
import Footer from '../components/tools/Footer';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';

const Booking = () => {
    const { t, i18n } = useTranslation(['components\\forms\\formTr']);

    const [showUl, setShowUl] = useState('');
    const [displayPopUp, setDisplayPopUp] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const setSelect = () => {
        if (showUl == '') {
            setShowUl('show-ul');
        } else {
            setShowUl('');
        }
    }

    return (
        <div className='contacts form-booking-choice'>
            <div className="img-background">
                <div className="main-title">
                    <span className="title">
                        {t('booking-title')}
                    </span>
                </div>
                <SocialNetworksMainBackground />
            </div>
            <div className='title-options'>
                <span>{t('options.title')}</span>
            </div>
            <div className="choice">
                {/*
                <div className="img-choice">
                    <img src="./img/components/form/hand-morph.webp" alt="" />
                </div>
                 */}
                <div className="choices">
                    <div className="block-hand">
                        <img src="./img/components/form/left-hand-gradiant.png" alt="" />
                        <div className="block red-one">
                            <div className="texts">
                                <p className='main'>
                                    {t('options.blocks.blue.content.one')}
                                </p>
                                <p className='italic'>
                                    {t('options.blocks.blue.content.two')}
                                </p>
                                <p className='main'>
                                    {t('options.blocks.blue.content.three')}
                                </p>
                                <div className="book">
                                    <span className='select-custom blue-btn' onClick={setSelect}>
                                        {t('options.blocks.blue.select.title')}
                                        <img src="./img/components/form/arrow-down-white.svg" alt="arrow" />
                                    </span>
                                </div>
                                <div className="center-ul">
                                    <ul className={showUl}>
                                        <li onClick={() => {
                                            window.open("https://reservation.seignanx.com/cours-collectif-1-seance-2-heures.html#media");
                                            setShowUl('');
                                        }
                                        }>
                                            {t('options.blocks.blue.select.options.classics.one-day')}
                                        </li>
                                        {/*
                                    <li onClick={() => {
                                        setDisplayPopUp(true);
                                        setShowUl('');
                                    }
                                    }>
                                        {t('options.blocks.blue.select.options.classics.two-days')}
                                    </li>
                                     */}
                                        <li onClick={() => {
                                            window.open("https://reservation.seignanx.com/cours-collectif-3-seances-2-heures.html#media");
                                            setShowUl('');
                                        }
                                        }>
                                            {t('options.blocks.blue.select.options.classics.three-days')}
                                        </li>
                                        <li onClick={() => {
                                            window.open("https://reservation.seignanx.com/cours-collectif-4-seances-2-heures.html#media");
                                            setShowUl('');
                                        }
                                        }>
                                            {t('options.blocks.blue.select.options.classics.four-days')}
                                        </li>
                                        <li onClick={() => {
                                            window.open("https://reservation.seignanx.com/cours-collectif-5-seances-2-heures.html#media");
                                            setShowUl('');
                                        }
                                        }>
                                            {t('options.blocks.blue.select.options.classics.five-days')}
                                        </li>
                                    </ul>
                                </div>
                                <p className='italic'>
                                    {t('options.blocks.blue.ps')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="block-hand">
                        <img src="./img/components/form/right-hand-gradiant.png" alt="" />
                        <div className="block blue-one">
                            <div className="texts">
                                <p className='main'>
                                    {t('options.blocks.red.content.one')}
                                </p>
                                <p className='italic'>
                                    {t('options.blocks.red.content.two')}
                                </p>
                                <p className='main'>
                                    {t('options.blocks.red.content.three')}

                                </p>
                                <div className="book">
                                    <a href='#bookingStart'><button className='red-btn'>
                                        {t('options.blocks.red.button')}
                                    </button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="separator">

                <span></span>

            </div>
            <Contact listenerIsContact={false} />

            {
                displayPopUp &&
                <div className="pop-up">
                    <div className="content-res">
                        <h2>{t('pop-up.title')}</h2>
                        <div className="choices">
                            <button onClick={() => setDisplayPopUp(false)}>{t('pop-up.ok')}</button>
                        </div>
                    </div>
                </div>
            }

            <Footer />
        </div >
    );
};

export default Booking;