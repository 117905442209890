import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/tools/Footer';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';

const SurfCourses = () => {
    const { t, i18n } = useTranslation(['pages\\surfCoursesTr']);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [onePerson, setOnePerson] = useState<string>('');
    const [btnOnePerson, setBtnOnePerson] = useState<string>('moins.svg');
    const [cssBlockOnePerson, setCssBlockOnePerson] = useState<string>('');
    const [twoPersons, setTwoPersons] = useState<string>('two-persons');
    const [btnTwoPersons, setBtnTwoPersons] = useState<string>('plus.svg');
    const [cssBlockTwoPerson, setCssBlockTwoPerson] = useState<string>('block-person-close');
    const [threePersons, setThreePersons] = useState<string>('three-persons');
    const [btnThreePersons, setBtnThreePersons] = useState<string>('plus.svg');
    const [cssBlockThreePerson, setCssBlockThreePerson] = useState<string>('block-person-close');
    const [fourPersons, setFourPersons] = useState<string>('four-persons');
    const [btnFourPersons, setBtnFourPersons] = useState<string>('plus.svg');
    const [cssBlockFourPerson, setCssBlockFourPerson] = useState<string>('block-person-close');

    const displayHidde = (nb: number) => {
        switch (nb) {
            case 1:
                if (onePerson === '') {
                    setOnePerson('one-person');
                    setBtnOnePerson('plus.svg');
                    setCssBlockOnePerson('block-person-close');
                } else {
                    setOnePerson('');
                    setBtnOnePerson('moins.svg');
                    setCssBlockOnePerson('');
                }
                break;

            case 2:
                if (twoPersons === '') {
                    setTwoPersons('two-persons');
                    setBtnTwoPersons('plus.svg');
                    setCssBlockTwoPerson('block-person-close');
                } else {
                    setTwoPersons('');
                    setBtnTwoPersons('moins.svg');
                    setCssBlockTwoPerson('');
                }
                break;

            case 3:
                if (threePersons === '') {
                    setThreePersons('three-persons');
                    setBtnThreePersons('plus.svg');
                    setCssBlockThreePerson('block-person-close');
                } else {
                    setThreePersons('');
                    setBtnThreePersons('moins.svg');
                    setCssBlockThreePerson('');
                }
                break;

            case 4:
                if (fourPersons === '') {
                    setFourPersons('four-persons');
                    setBtnFourPersons('plus.svg');
                    setCssBlockFourPerson('block-person-close');
                } else {
                    setFourPersons('');
                    setBtnFourPersons('moins.svg');
                    setCssBlockFourPerson('');
                }
                break;
        }
    }

    const [displayPopUp, setDisplayPopUp] = useState<boolean>(false);
    const [titlePopUp, setTitlePopUp] = useState<string>("");
    const [amountPopUp, setAmountPopUp] = useState<string>("");
    const [pricePopUp, setPricePopUp] = useState<string>("");

    const displayPopUpReservation = (title: string, amount: string, price: string) => {

        setTitlePopUp(title);
        setAmountPopUp(amount);
        setPricePopUp(price);
        setDisplayPopUp(true);

    }

    return (
        <div className='surf-courses'>
            <div className="img-background">
                <div className="main-title">
                    <span className="title">
                        {t('title')}
                    </span>
                </div>

                <SocialNetworksMainBackground />
            </div>

            <div className="container">
                <div className="block-course">
                    <img src="img/components/card-course/surfboard.webp" alt="Cours classique" />
                    <div className="title-global red-title">
                        {t('classic.title')}
                    </div>
                    <div className="description-global red-description">
                        {t('classic.description')}
                    </div>
                </div>

                <div className="block-prices">
                    <div className="title-global red-title">
                        <p>
                            {t('classic.prices.title')}
                        </p>
                    </div>
                    <div className="prices"
                        onClick={() => displayPopUpReservation(
                            t('classic.title'),
                            t('classic.prices.one-day.time'),
                            t('classic.prices.one-day.price')
                        )} >
                        <div className="time">
                            <p>
                                {t('classic.prices.one-day.time')}
                            </p>
                        </div>
                        <div className="duration">
                            <p>
                                {t('classic.prices.one-day.duration')}
                            </p>
                        </div>
                        <div className="price">
                            <p>
                                {t('classic.prices.one-day.price')}
                            </p>
                        </div>
                    </div>
                    <div className="prices"
                        onClick={() => displayPopUpReservation(
                            t('classic.title'),
                            t('classic.prices.two-days.time'),
                            t('classic.prices.two-days.price')
                        )} >
                        <div className="time">
                            <p>
                                {t('classic.prices.two-days.time')}
                            </p>
                        </div>
                        <div className="duration">
                            <p>
                                {t('classic.prices.two-days.duration')}
                            </p>
                        </div>
                        <div className="price">
                            <p>
                                {t('classic.prices.two-days.price')}
                            </p>
                        </div>
                    </div>
                    <div className="prices"
                        onClick={() => displayPopUpReservation(
                            t('classic.title'),
                            t('classic.prices.three-days.time'),
                            t('classic.prices.three-days.price')
                        )}>
                        <div className="time">
                            <p>
                                {t('classic.prices.three-days.time')}
                            </p>
                        </div>
                        <div className="duration">
                            <p>
                                {t('classic.prices.three-days.duration')}
                            </p>
                        </div>
                        <div className="price">
                            <p>
                                {t('classic.prices.three-days.price')}
                            </p>
                        </div>
                    </div>
                    <div className="prices"
                        onClick={() => displayPopUpReservation(
                            t('classic.title'),
                            t('classic.prices.four-days.time'),
                            t('classic.prices.four-days.price')
                        )}>
                        <div className="time">
                            <p>
                                {t('classic.prices.four-days.time')}
                            </p>
                        </div>
                        <div className="duration">
                            <p>
                                {t('classic.prices.four-days.duration')}
                            </p>
                        </div>
                        <div className="price">
                            <p>
                                {t('classic.prices.four-days.price')}
                            </p>
                        </div>
                    </div>
                    <div className="prices"
                        onClick={() => displayPopUpReservation(
                            t('classic.title'),
                            t('classic.prices.five-days.time'),
                            t('classic.prices.five-days.price')
                        )}>
                        <div className="time">
                            <p>
                                {t('classic.prices.five-days.time')}
                            </p>
                        </div>
                        <div className="duration">
                            <p>
                                {t('classic.prices.five-days.duration')}
                            </p>
                        </div>
                        <div className="price">
                            <p>
                                {t('classic.prices.five-days.price')}
                            </p>
                        </div>
                    </div>
                </div>


                <div className="block-course">
                    <img src="img/components/card-course/surf-blue.webp" alt="Cours classique" />
                    <div className="title-global blue-text">
                        {t('development.title')}
                    </div>
                    <div className="description-global blue-text">
                        <p>
                            {t('development.description.part1')}
                        </p>
                        <p>
                            {t('development.description.part2')}
                        </p>
                    </div>
                </div>

                <div className="block-prices">
                    <div className="title-global blue-text">
                        {t('development.prices.title')}
                    </div>


                    <div className={"block-person " + cssBlockOnePerson} onClick={() => displayHidde(1)}>
                        <p className='title-price'>
                            {t('development.prices.one-person.title')}
                        </p>
                        <img src={"img/pages/surfCourses/" + btnOnePerson} alt="-" />
                    </div>

                    <div className={onePerson}>
                        <div className="block-details light-blue"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.one-person.title') + "-" + t('development.prices.one-person.duration.part1'),
                                t('development.prices.one-person.price.part1')
                            )}>
                            <div className="duration">
                                {t('development.prices.one-person.duration.part1')}
                            </div>
                            <div className="price">
                                {t('development.prices.one-person.price.part1')}
                            </div>
                        </div>
                        <div className="block-details middle-blue"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.one-person.title') + "-" + t('development.prices.one-person.duration.part2'),
                                t('development.prices.one-person.price.part2')
                            )}>
                            <div className="duration">
                                {t('development.prices.one-person.duration.part2')}
                            </div>
                            <div className="price">
                                {t('development.prices.one-person.price.part2')}
                            </div>
                        </div>
                        <div className="block-details light-blue"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.one-person.title') + "-" + t('development.prices.one-person.duration.part3'),
                                t('development.prices.one-person.price.part3')
                            )}>
                            <div className="duration">
                                {t('development.prices.one-person.duration.part3')}
                            </div>
                            <div className="price">
                                {t('development.prices.one-person.price.part3')}
                            </div>
                        </div>
                        <div className="block-details middle-blue"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.one-person.title') + "-" + t('development.prices.one-person.duration.part4'),
                                t('development.prices.one-person.price.part4')
                            )}>
                            <div className="duration">
                                {t('development.prices.one-person.duration.part4')}
                            </div>
                            <div className="price">
                                {t('development.prices.one-person.price.part4')}
                            </div>
                        </div>
                        <div className="block-details light-blue last-item"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.one-person.title') + "-" + t('development.prices.one-person.duration.part5'),
                                t('development.prices.one-person.price.part5')
                            )}>
                            <div className="duration">
                                {t('development.prices.one-person.duration.part5')}
                            </div>
                            <div className="price">
                                {t('development.prices.one-person.price.part5')}
                            </div>
                        </div>

                    </div>

                    <div className={"block-person " + cssBlockTwoPerson} onClick={() => displayHidde(2)}>
                        <p className='title-price'>
                            {t('development.prices.two-persons.title')}
                        </p>
                        <img src={"img/pages/surfCourses/" + btnTwoPersons} alt="-" />
                    </div>

                    <div className={twoPersons}>
                        <div className="block-details light-blue"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.two-persons.title') + "-" + t('development.prices.two-persons.duration.part1'),
                                t('development.prices.two-persons.price.part1')
                            )}>
                            <div className="duration">
                                {t('development.prices.two-persons.duration.part1')}
                            </div>
                            <div className="price">
                                {t('development.prices.two-persons.price.part1')}
                            </div>
                        </div>
                        <div className="block-details middle-blue"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.two-persons.title') + "-" + t('development.prices.two-persons.duration.part2'),
                                t('development.prices.two-persons.price.part2')
                            )}>
                            <div className="duration">
                                {t('development.prices.two-persons.duration.part2')}
                            </div>
                            <div className="price">
                                {t('development.prices.two-persons.price.part2')}
                            </div>
                        </div>
                        <div className="block-details light-blue"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.two-persons.title') + "-" + t('development.prices.two-persons.duration.part3'),
                                t('development.prices.two-persons.price.part3')
                            )}>
                            <div className="duration">
                                {t('development.prices.two-persons.duration.part3')}
                            </div>
                            <div className="price">
                                {t('development.prices.two-persons.price.part3')}
                            </div>
                        </div>
                        <div className="block-details middle-blue"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.two-persons.title') + "-" + t('development.prices.two-persons.duration.part4'),
                                t('development.prices.two-persons.price.part4')
                            )}>
                            <div className="duration">
                                {t('development.prices.two-persons.duration.part4')}
                            </div>
                            <div className="price">
                                {t('development.prices.two-persons.price.part4')}
                            </div>
                        </div>
                        <div className="block-details light-blue last-item"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.two-persons.title') + "-" + t('development.prices.two-persons.duration.part5'),
                                t('development.prices.two-persons.price.part5')
                            )}>
                            <div className="duration">
                                {t('development.prices.two-persons.duration.part5')}
                            </div>
                            <div className="price">
                                {t('development.prices.two-persons.price.part5')}
                            </div>
                        </div>

                    </div>

                    <div className={"block-person " + cssBlockThreePerson} onClick={() => displayHidde(3)}>
                        <p className='title-price'>
                            {t('development.prices.three-persons.title')}
                        </p>
                        <img src={"img/pages/surfCourses/" + btnThreePersons} alt="-" />
                    </div>

                    <div className={threePersons}>
                        <div className="block-details light-blue"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.three-persons.title') + "-" + t('development.prices.three-persons.duration.part1'),
                                t('development.prices.three-persons.price.part1')
                            )}>
                            <div className="duration">
                                {t('development.prices.three-persons.duration.part1')}
                            </div>
                            <div className="price">
                                {t('development.prices.three-persons.price.part1')}
                            </div>
                        </div>
                        <div className="block-details middle-blue"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.three-persons.title') + "-" + t('development.prices.three-persons.duration.part2'),
                                t('development.prices.three-persons.price.part2')
                            )}>
                            <div className="duration">
                                {t('development.prices.three-persons.duration.part2')}
                            </div>
                            <div className="price">
                                {t('development.prices.three-persons.price.part2')}
                            </div>
                        </div>
                        <div className="block-details light-blue"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.three-persons.title') + "-" + t('development.prices.three-persons.duration.part3'),
                                t('development.prices.three-persons.price.part3')
                            )}>
                            <div className="duration">
                                {t('development.prices.three-persons.duration.part3')}
                            </div>
                            <div className="price">
                                {t('development.prices.three-persons.price.part3')}
                            </div>
                        </div>
                        <div className="block-details middle-blue"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.three-persons.title') + "-" + t('development.prices.three-persons.duration.part4'),
                                t('development.prices.three-persons.price.part4')
                            )}>
                            <div className="duration">
                                {t('development.prices.three-persons.duration.part4')}
                            </div>
                            <div className="price">
                                {t('development.prices.three-persons.price.part4')}
                            </div>
                        </div>
                        <div className="block-details light-blue last-item"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.three-persons.title') + "-" + t('development.prices.three-persons.duration.part5'),
                                t('development.prices.three-persons.price.part5')
                            )}>
                            <div className="duration">
                                {t('development.prices.three-persons.duration.part5')}
                            </div>
                            <div className="price">
                                {t('development.prices.three-persons.price.part5')}
                            </div>
                        </div>

                    </div>

                    <div className={"block-person " + cssBlockFourPerson} onClick={() => displayHidde(4)}>
                        <p className='title-price'>
                            {t('development.prices.four-persons.title')}
                        </p>
                        <img src={"img/pages/surfCourses/" + btnFourPersons} alt="-" />
                    </div>

                    <div className={fourPersons}>
                        <div className="block-details light-blue"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.four-persons.title') + "-" + t('development.prices.four-persons.duration.part1'),
                                t('development.prices.four-persons.price.part1')
                            )}>
                            <div className="duration">
                                {t('development.prices.four-persons.duration.part1')}
                            </div>
                            <div className="price">
                                {t('development.prices.four-persons.price.part1')}
                            </div>
                        </div>
                        <div className="block-details middle-blue"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.four-persons.title') + "-" + t('development.prices.four-persons.duration.part2'),
                                t('development.prices.four-persons.price.part2')
                            )}>
                            <div className="duration">
                                {t('development.prices.four-persons.duration.part2')}
                            </div>
                            <div className="price">
                                {t('development.prices.four-persons.price.part2')}
                            </div>
                        </div>
                        <div className="block-details light-blue"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.four-persons.title') + "-" + t('development.prices.four-persons.duration.part3'),
                                t('development.prices.four-persons.price.part3')
                            )}>
                            <div className="duration">
                                {t('development.prices.four-persons.duration.part3')}
                            </div>
                            <div className="price">
                                {t('development.prices.four-persons.price.part3')}
                            </div>
                        </div>
                        <div className="block-details middle-blue"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.four-persons.title') + "-" + t('development.prices.four-persons.duration.part4'),
                                t('development.prices.four-persons.price.part4')
                            )}>
                            <div className="duration">
                                {t('development.prices.four-persons.duration.part4')}
                            </div>
                            <div className="price">
                                {t('development.prices.four-persons.price.part4')}
                            </div>
                        </div>
                        <div className="block-details light-blue last-item"
                            onClick={() => displayPopUpReservation(
                                t('development.title'),
                                t('development.prices.four-persons.title') + "-" + t('development.prices.four-persons.duration.part5'),
                                t('development.prices.four-persons.price.part5')
                            )}>
                            <div className="duration">
                                {t('development.prices.four-persons.duration.part5')}
                            </div>
                            <div className="price">
                                {t('development.prices.four-persons.price.part5')}
                            </div>
                        </div>

                    </div>

                </div>

                <div className="block-course">
                    <img src="img/components/card-course/van.webp" alt="Cours classique" />
                    <div className="title-global red-title">
                        {t('pick-up.title')}
                    </div>
                    <div className="description-global red-description">
                        {t('pick-up.description')}
                    </div>
                </div>

                <div className="block-prices">
                    <div className="title-global red-title">
                        <p>
                            {t('pick-up.prices.title')}
                        </p>
                        <p className="sub-title">
                            {t('pick-up.prices.sub-title')}
                        </p>
                    </div>
                    <div className="prices"
                        onClick={() => displayPopUpReservation(
                            t('pick-up.title'),
                            t('pick-up.prices.one-day.time'),
                            t('pick-up.prices.one-day.price')
                        )}>
                        <div className="time">
                            <p>
                                {t('pick-up.prices.one-day.time')}
                            </p>
                        </div>
                        <div className="duration">
                            <p>
                                {t('pick-up.prices.one-day.duration')}
                            </p>
                        </div>
                        <div className="price">
                            <p>
                                {t('pick-up.prices.one-day.price')}
                            </p>
                        </div>
                    </div>
                    <div className="prices"
                        onClick={() => displayPopUpReservation(
                            t('pick-up.title'),
                            t('pick-up.prices.three-days.time'),
                            t('pick-up.prices.three-days.price')
                        )}>
                        <div className="time">
                            <p>
                                {t('pick-up.prices.three-days.time')}
                            </p>
                        </div>
                        <div className="duration">
                            <p>
                                {t('pick-up.prices.three-days.duration')}
                            </p>
                        </div>
                        <div className="price">
                            <p>
                                {t('pick-up.prices.three-days.price')}
                            </p>
                        </div>
                    </div>
                    <div className="prices"
                        onClick={() => displayPopUpReservation(
                            t('pick-up.title'),
                            t('pick-up.prices.five-days.time'),
                            t('pick-up.prices.five-days.price')
                        )}>
                        <div className="time">
                            <p>
                                {t('pick-up.prices.five-days.time')}
                            </p>
                        </div>
                        <div className="duration">
                            <p>
                                {t('pick-up.prices.five-days.duration')}
                            </p>
                        </div>
                        <div className="price">
                            <p>
                                {t('pick-up.prices.five-days.price')}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="reserve">
                    <button onClick={() => navigate('/booking')} className="btn-blue">
                        {t('reserve')}
                    </button>
                </div>

            </div>

            {displayPopUp &&
                <div className="pop-up">

                    <div className="content-res">
                        <h2>{titlePopUp} - {amountPopUp} - {pricePopUp}</h2>
                        <div className="choices">
                            <button onClick={() => navigate("/booking?resa=" + titlePopUp + ";" + amountPopUp + ";" + pricePopUp)}>{t('booking')}</button>
                            <button onClick={() => setDisplayPopUp(false)}>{t('cancel')}</button>
                        </div>
                    </div>

                </div>
            }
            <Footer />
        </div>
    );
};

export default SurfCourses;