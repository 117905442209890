import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/tools/Footer';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';

const SurfTrips = () => {
    const { t, i18n } = useTranslation(['pages\\surfTripsTr']);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='trip'>
            <div className="img-background">
                <div className="main-title">
                    <p className="title">
                        {t('title')}
                    </p>
                    <p className="description">
                        {t('description')}
                    </p>
                </div>

                <SocialNetworksMainBackground/>
            </div>

            <div className="container">
                <div className="block-travel">
                    <img src="img/pages/surfTrips/maroc.webp" alt="Maroc" />
                    <div className="block-description">
                        <p className="title-blue-global">
                            {t('maroc.title')}
                        </p>
                        <p className='description-global'>
                            {t('maroc.description.part1')}
                        </p>
                        <p className='description-global'>
                            {t('maroc.description.part2')}
                        </p>
                        <p className='description-global'>
                            {t('maroc.description.part3')}
                        </p>
                    </div>
                </div>

                <div className="block-travel block-reverse">
                    <img src="img/pages/surfTrips/pays-basque.webp" alt="Pays basque" />
                    <div className="block-description">
                        <p className="title-blue-global">
                            {t('pays-basque.title')}
                        </p>
                        <p className='description-global'>
                            {t('pays-basque.description.part1')}
                        </p>
                        <p className='description-global'>
                            {t('pays-basque.description.part2')}
                        </p>
                    </div>
                </div>

                <div className="more">
                    <button onClick={() => navigate('/contacts?subject=[SURF TRIPS]')} className="btn-blue">
                        {t('more')}
                    </button>
                </div>

            </div>

            <Footer />
        </div>
    );
};

export default SurfTrips;