import { t } from 'i18next';
import React from 'react';

interface INotif {
    listenerName: string,
    listenerComment: string,
    listenerPicture: string
}

const CardOpinion: React.FC<INotif> = ({ listenerName, listenerComment, listenerPicture }) => {

    let opinions_url = process.env.REACT_APP_OPINIONS;
    if(window.innerWidth<1200){
        opinions_url = process.env.REACT_APP_OPINIONS_SMARTPHONE;
    }

    return (
        <div className='card-opinion'>
            <img className='img-card' src={"img/components/card-opinion/" + listenerPicture} alt="" />
            <div className="infos">
                <div className="name">
                    <p>
                        {listenerName}
                    </p>
                    <a onClick={() => window.open(opinions_url)}>
                        <img src="img/components/card-opinion/plus.svg" alt="plus" />
                    </a>
                </div>
                <div className="stars">
                    <img src="img/components/card-opinion/star.png" alt="star" />
                    <img src="img/components/card-opinion/star.png" alt="star" />
                    <img src="img/components/card-opinion/star.png" alt="star" />
                    <img src="img/components/card-opinion/star.png" alt="star" />
                    <img src="img/components/card-opinion/star.png" alt="star" />
                </div>
                <div className="comment">
                    <span>
                        {listenerComment}
                    </span>
                </div>
            </div>

        </div>
    );
};

export default CardOpinion;