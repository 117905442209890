import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/tools/Footer';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';

const Location = () => {
    const { t, i18n } = useTranslation(['pages\\locationTr']);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [surf, setSurf] = useState<string>('');
    const [btnSurf, setBtnSurf] = useState<string>('moins.svg');
    const [cssBlockSurf, setCssBlockSurf] = useState<string>('');
    const [surfAndWetsuit, setSurfAndWetsuit] = useState<string>('surf-wetsuit');
    const [btnSurfAndWetsuit, setBtnSurfAndWetsuit] = useState<string>('plus.svg');
    const [cssBlockSurfAndWetsuit, setCssBlockSurfAndWetsuit] = useState<string>('block-person-close');
    const [bodyboard, setBodyboard] = useState<string>('bodyboard');
    const [btnBodyboard, setBtnBodyboard] = useState<string>('plus.svg');
    const [cssBlockBodyboard, setCssBlockBodyboard] = useState<string>('block-person-close');
    const [bodyboardAndWetsuit, setBodyboardAndWetsuit] = useState<string>('body-wetsuit');
    const [btnBodyboardAndWetsuit, setBtnBodyboardAndWetsuit] = useState<string>('plus.svg');
    const [cssBlockBodyboardAndWetsuit, setCssBlockBodyboardAndWetsuit] = useState<string>('block-person-close');


    const displayHidde = (nb: number) => {
        switch (nb) {
            case 1:
                if (surf === '') {
                    setSurf('surf');
                    setBtnSurf('plus.svg');
                    setCssBlockSurf('block-person-close');
                } else {
                    setSurf('');
                    setBtnSurf('moins.svg');
                    setCssBlockSurf('');
                }
                break;

            case 2:
                if (surfAndWetsuit === '') {
                    setSurfAndWetsuit('surf-wetsuit');
                    setBtnSurfAndWetsuit('plus.svg');
                    setCssBlockSurfAndWetsuit('block-person-close');
                } else {
                    setSurfAndWetsuit('');
                    setBtnSurfAndWetsuit('moins.svg');
                    setCssBlockSurfAndWetsuit('');
                }
                break;

            case 3:
                if (bodyboard === '') {
                    setBodyboard('bodyboard');
                    setBtnBodyboard('plus.svg');
                    setCssBlockBodyboard('block-person-close');
                } else {
                    setBodyboard('');
                    setBtnBodyboard('moins.svg');
                    setCssBlockBodyboard('');
                }
                break;

            case 4:
                if (bodyboardAndWetsuit === '') {
                    setBodyboardAndWetsuit('body-wetsuit');
                    setBtnBodyboardAndWetsuit('plus.svg');
                    setCssBlockBodyboardAndWetsuit('block-person-close');
                } else {
                    setBodyboardAndWetsuit('');
                    setBtnBodyboardAndWetsuit('moins.svg');
                    setCssBlockBodyboardAndWetsuit('');
                }
                break;
        }
    }

    return (
        <div className='location'>
            <div className="img-background">
                <div className="main-title">
                    <span className="title">
                        {t('title')}
                    </span>
                </div>

                <SocialNetworksMainBackground />
            </div>

            <div className="color-back">
                <div className="container">
                    <div className="block-location">
                        <div className="img-location">
                            <img src="img/pages/location/location.webp" alt="Location" />
                        </div>
                        <div className="description-location">
                            <div className="title-global">
                                {t('location.title')}
                            </div>
                            <div className="description-global">
                                <p>
                                    {t('location.description.part1')}
                                </p>
                                <p>
                                    {t('location.description.part2')}
                                </p>
                                <p>
                                    {t('location.description.part3')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="block-prices">
                        <p className="title-global">
                            {t('prices.title')}
                        </p>


                        <div className={"block-person " + cssBlockSurf} onClick={() => displayHidde(1)}>
                            <p className='title-price'>
                                {t('prices.surf.title')}
                            </p>
                            <img src={"img/pages/surfCourses/" + btnSurf} alt="-" />
                        </div>

                        <div className={surf}>
                            {/*<div className="block-details light-blue">
                                <div className="duration">
                                    {t('prices.surf.options.durations.part1')}
                                </div>
                                <div className="price">
                                    {t('prices.surf.options.price.part1')}
                                </div>
                            </div>
                            <div className="block-details middle-blue">
                                <div className="duration">
                                    {t('prices.surf.options.durations.part2')}
                                </div>
                                <div className="price">
                                    {t('prices.surf.options.price.part2')}
                                </div>
                            </div>*/}
                            <div className="block-details light-blue">
                                <div className="duration">
                                    {t('prices.surf.options.durations.part3')}
                                </div>
                                <div className="price">
                                    {t('prices.surf.options.price.part3')}
                                </div>
                            </div>
                            <div className="block-details middle-blue last-item">
                                <div className="duration">
                                    {t('prices.surf.options.durations.part4')}
                                </div>
                                <div className="price">
                                    {t('prices.surf.options.price.part4')}
                                </div>
                            </div>

                        </div>

                        <div className={"block-person " + cssBlockSurfAndWetsuit} onClick={() => displayHidde(2)}>
                            <p className='title-price'>
                                {t('prices.surf-wetsuit.title')}
                            </p>
                            <img src={"img/pages/surfCourses/" + btnSurfAndWetsuit} alt="-" />
                        </div>

                        <div className={surfAndWetsuit}>
                            {/*<div className="block-details light-blue">
                                <div className="duration">
                                    {t('prices.surf-wetsuit.options.durations.part1')}
                                </div>
                                <div className="price">
                                    {t('prices.surf-wetsuit.options.price.part1')}
                                </div>
                            </div>
                            <div className="block-details middle-blue">
                                <div className="duration">
                                    {t('prices.surf-wetsuit.options.durations.part2')}
                                </div>
                                <div className="price">
                                    {t('prices.surf-wetsuit.options.price.part2')}
                                </div>
                            </div>*/}
                            <div className="block-details light-blue">
                                <div className="duration">
                                    {t('prices.surf-wetsuit.options.durations.part3')}
                                </div>
                                <div className="price">
                                    {t('prices.surf-wetsuit.options.price.part3')}
                                </div>
                            </div>
                            <div className="block-details middle-blue last-item">
                                <div className="duration">
                                    {t('prices.surf-wetsuit.options.durations.part4')}
                                </div>
                                <div className="price">
                                    {t('prices.surf-wetsuit.options.price.part4')}
                                </div>
                            </div>

                        </div>

                        <div className={"block-person " + cssBlockBodyboard} onClick={() => displayHidde(3)}>
                            <p className='title-price'>
                                {t('prices.bodyboard.title')}
                            </p>
                            <img src={"img/pages/surfCourses/" + btnBodyboard} alt="-" />
                        </div>

                        <div className={bodyboard}>
                            {/*<div className="block-details light-blue">
                                <div className="duration">
                                    {t('prices.bodyboard.options.durations.part1')}
                                </div>
                                <div className="price">
                                    {t('prices.bodyboard.options.price.part1')}
                                </div>
                            </div>
                            <div className="block-details middle-blue">
                                <div className="duration">
                                    {t('prices.bodyboard.options.durations.part2')}
                                </div>
                                <div className="price">
                                    {t('prices.bodyboard.options.price.part2')}
                                </div>
                            </div>*/}
                            <div className="block-details light-blue">
                                <div className="duration">
                                    {t('prices.bodyboard.options.durations.part3')}
                                </div>
                                <div className="price">
                                    {t('prices.bodyboard.options.price.part3')}
                                </div>
                            </div>
                            <div className="block-details middle-blue last-item">
                                <div className="duration">
                                    {t('prices.bodyboard.options.durations.part4')}
                                </div>
                                <div className="price">
                                    {t('prices.bodyboard.options.price.part4')}
                                </div>
                            </div>

                        </div>

                        <div className={"block-person " + cssBlockBodyboardAndWetsuit} onClick={() => displayHidde(4)}>
                            <p className='title-price'>
                                {t('prices.bodyboard-wetsuit.title')}
                            </p>
                            <img src={"img/pages/surfCourses/" + btnBodyboardAndWetsuit} alt="-" />
                        </div>

                        <div className={bodyboardAndWetsuit}>
                            {/*<div className="block-details light-blue">
                                <div className="duration">
                                    {t('prices.bodyboard-wetsuit.options.durations.part1')}
                                </div>
                                <div className="price">
                                    {t('prices.bodyboard-wetsuit.options.price.part1')}
                                </div>
                            </div>
                            <div className="block-details middle-blue">
                                <div className="duration">
                                    {t('prices.bodyboard-wetsuit.options.durations.part2')}
                                </div>
                                <div className="price">
                                    {t('prices.bodyboard-wetsuit.options.price.part2')}
                                </div>
                            </div>*/}
                            <div className="block-details light-blue">
                                <div className="duration">
                                    {t('prices.bodyboard-wetsuit.options.durations.part3')}
                                </div>
                                <div className="price">
                                    {t('prices.bodyboard-wetsuit.options.price.part3')}
                                </div>
                            </div>
                            <div className="block-details middle-blue last-item">
                                <div className="duration">
                                    {t('prices.bodyboard-wetsuit.options.durations.part4')}
                                </div>
                                <div className="price">
                                    {t('prices.bodyboard-wetsuit.options.price.part4')}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="btn-middle">
                        <button onClick={() => navigate('/contacts?subject=[LOCATION]')} className="btn-transparent">
                            {t('more')}
                        </button>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Location;