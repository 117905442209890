import { useNavigate } from "react-router-dom";

const Footer = () => {

    const navigate = useNavigate();

    return (
        <div className='footer'>

            <div className="content">
                <div className="social-networks">
                    <a onClick={() => window.open(process.env.REACT_APP_INSTAGRAM)}>
                        <div className="social-logo">
                            <span>
                                <img src="img/components/tools/instagram-blue.svg" alt="Instagram" />
                            </span>
                        </div>
                    </a>
                    <a onClick={() => window.open(process.env.REACT_APP_FACEBOOK)}>
                        <div className="social-logo">
                            <span>
                                <img src="img/components/tools/facebook-blue.svg" alt="Facebook" />
                            </span>
                        </div>
                    </a>
                </div>

                <div className="text-footer">
                    <p>
                        Une réalisation <a onClick={() => window.open("https://manipogo.fr/")}>manipogo.fr</a> | <a style={{ "cursor": "pointer" }} onClick={() => navigate("/values#eco-responsable")} >Eco-responsable</a> | <a onClick={() => window.open("/cgu")}>CGU</a> | <a onClick={() => window.open("/cgv")}>CGV</a>
                    </p>
                    <p>
                        ©️2022 SURF PERSPECTIVE
                    </p>
                </div>

            </div>


        </div>
    );
};

export default Footer;