import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {

    const { t, i18n } = useTranslation(['components\\tools\\navbarTr']);

    const [frSelectedCss, setFrSelectedCss] = useState<boolean>(true);
    const [enSelectedCss, setEnSelectedCss] = useState<boolean>(false);
    const [esSelectedCss, setEsSelectedCss] = useState<boolean>(false);
    const [lngSelected, setLngSelected] = useState<string>('fr');

    const [displayNavBar, setDisplayNavBar] = useState<string>('navbar');

    const navigate = useNavigate();

    let yScroll: number = 0;
    // The scroll listener
    const handleScroll = useCallback(() => {

        if (window.scrollY > yScroll) {
            setDisplayNavBar('navbar hidde-navbar');
            setCssDisplayMenu('menu-hidden');
        } else {
            setDisplayNavBar('navbar display-navbar');
        }
        yScroll = window.scrollY;

    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });

    useEffect(() => {

        if (localStorage.lng === undefined) {
            setLngSelected('fr');
            changeLanguage('fr');
            localStorage.setItem("lng", "fr");
        } else {
            setLngSelected(localStorage.lng);
            changeLanguage(localStorage.lng);
        }

    }, []);

    const changeLanguage = (lng: any) => {
        i18n.changeLanguage(lng);
        setLngSelected(lng);
        localStorage.lng = lng;

        switch (lng) {
            case "fr":
                setFrSelectedCss(true);
                setEnSelectedCss(false);
                setEsSelectedCss(false)
                break;
            case "en":
                setFrSelectedCss(false);
                setEnSelectedCss(true);
                setEsSelectedCss(false)
                break;
            case "es":
                setFrSelectedCss(false);
                setEnSelectedCss(false);
                setEsSelectedCss(true)
                break;

        }

        setCssFlags('');

    };

    const [cssPresentation, setCssPresentation] = useState<string>('');
    const [cssFormules, setCssFormules] = useState<string>('');
    const [cssFlags, setCssFlags] = useState<string>('');

    const displayMenu = (label: string) => {

        switch (label) {
            case "presentation":
                if (cssPresentation === '') {
                    setCssPresentation('sub-active');
                } else {
                    setCssPresentation('')
                }
                setCssFormules('');
                setCssFlags('');
                break;
            case "formules":
                setCssPresentation('');
                if (cssFormules === '') {
                    setCssFormules('sub-active');
                } else {
                    setCssFormules('')
                }
                setCssFlags('');
                break;
            case "flags":
                setCssPresentation('');
                setCssFormules('');
                if (cssFlags === '') {
                    setCssFlags('sub-active');
                } else {
                    setCssFlags('');
                }
                break;
        }

    }

    const [cssDisplayMenu, setCssDisplayMenu] = useState<string>('menu-hidden');

    const displayMenuResponsive = () => {
        if (cssDisplayMenu === 'menu-hidden') {
            setCssDisplayMenu('');
        } else {
            setCssDisplayMenu('menu-hidden');
        }
        setCssPresentation('');
        setCssFormules('');
        setCssFlags('');
    }

    const navigateTo = (menu: string) => {
        setCssDisplayMenu('menu-hidden');
        setCssPresentation('')
        setCssFormules('')
        setCssFlags('');
        navigate(menu);
    }

    return (
        <div className={displayNavBar}>

            <div className="responsive">

                <div className="logo" onClick={() => navigate('/')}>
                    <img src="img/components/tools/logo_sp.webp" alt="Surf perspective" />
                </div>

                <div className="div-reserve">
                    <div className={'language-choice-mobile ' + cssFlags}>
                        {frSelectedCss &&
                            <div className="div-img-social" onClick={() => displayMenu('flags')}>
                                <img className='img-social flag' src="./img/components/tools/france.webp" alt="Drapeau français" />
                            </div>
                        }
                        {enSelectedCss &&
                            <div className="div-img-social" onClick={() => displayMenu('flags')}>
                                <img className='img-social flag' src="./img/components/tools/royaume-uni.webp" alt="English flag" />
                            </div>
                        }
                        {esSelectedCss &&
                            <div className="div-img-social" onClick={() => displayMenu('flags')}>
                                <img className='img-social flag' src="./img/components/tools/spain.webp" alt="Bandera española" />
                            </div>
                        }
                        <div className="sub-menu">
                            <ul className='flags'>
                                <li>
                                    <span onClick={() => changeLanguage('fr')}>
                                        <img className='flag' src="./img/components/tools/france.webp" alt="Drapeau français" />
                                    </span>
                                </li>
                                <li>
                                    <span onClick={() => changeLanguage('en')}>
                                        <img className='flag' src="./img/components/tools/royaume-uni.webp" alt="English flag" />
                                    </span>
                                </li>
                                <li>
                                    <span onClick={() => changeLanguage('es')}>
                                        <img className='flag' src="./img/components/tools/spain.webp" alt="Bandera española" />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button className="btn-reserve" onClick={() => navigateTo('/booking')}><span></span> {t('reserve')}</button>
                </div>

                <div className="btn-burger">
                    <img src="img/components/tools/bars.svg" alt="menu" onClick={displayMenuResponsive} />
                </div>
            </div>

            <div className={"menu " + cssDisplayMenu}>
                <ul>
                    <li className={cssPresentation}>
                        <div className="div-sub-menu">
                            <div className="formule" onClick={() => displayMenu('presentation')}>
                                <span> {t('presentation.title')} </span>
                                <img src="img/components/tools/sub-menu.svg" alt="sub-menu" />
                            </div>
                            <div className="sub-menu">
                                <ul>
                                    <li onClick={() => navigateTo('/coach')}>
                                        <span>
                                            {t('presentation.sub-menu.coach')}
                                        </span>
                                    </li>
                                    <li onClick={() => navigateTo('/values')}>
                                        <span>
                                            {t('presentation.sub-menu.values')}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li className={cssFormules}>
                        <div className="div-sub-menu">
                            <div className="formule" onClick={() => displayMenu('formules')}>
                                <span>  {t('formulas.title')}  </span>
                                <img src="img/components/tools/sub-menu.svg" alt="sub-menu" />
                            </div>
                            <div className="sub-menu">
                                <ul>
                                    <li onClick={() => navigateTo('/cours')}>
                                        <span>
                                            {t('formulas.sub-menu.classes')}
                                        </span>
                                    </li>
                                    <li onClick={() => navigateTo('/trips')}>
                                        <span>
                                            {t('formulas.sub-menu.trips')}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li onClick={() => navigateTo('/camp')}> <span> {t('camp')} </span> </li>
                    <li onClick={() => navigateTo('/location')}> <span> {t('location')} </span> </li>
                    <li onClick={() => navigateTo('/gallery')}> <span> {t('gallery')} </span> </li>
                    <li onClick={() => navigateTo('/contacts')}> <span> {t('contacts')} </span> </li>
                    <li className={cssFlags} >

                        <div className="div-sub-menu">
                            <div className="formule">
                                <a onClick={() => window.open(process.env.REACT_APP_FACEBOOK)} >
                                    <div className="div-img-social networks-display">
                                        <img className='img-social' src="img/components/tools/facebook.svg" alt="Facebook" />

                                    </div>
                                </a>
                                <a onClick={() => window.open(process.env.REACT_APP_INSTAGRAM)} >
                                    <div className="div-img-social networks-display">
                                        <img className='img-social' src="img/components/tools/instagram.svg" alt="Instagram" />
                                    </div>
                                </a>
                                <a onClick={() => window.open(process.env.REACT_APP_MAPS)} >
                                    <div className="div-img-social networks-display">
                                        <img className='img-social' src="img/components/tools/maps.svg" alt="Maps" />
                                    </div>
                                </a>
                                <div className="language-choice-desktop">
                                    {frSelectedCss &&
                                        <div className="div-img-social" onClick={() => displayMenu('flags')}>
                                            <img className='img-social flag' src="./img/components/tools/france.webp" alt="Drapeau français" />
                                        </div>
                                    }
                                    {enSelectedCss &&
                                        <div className="div-img-social" onClick={() => displayMenu('flags')}>
                                            <img className='img-social flag' src="./img/components/tools/royaume-uni.webp" alt="English flag" />
                                        </div>
                                    }
                                    {esSelectedCss &&
                                        <div className="div-img-social" onClick={() => displayMenu('flags')}>
                                            <img className='img-social flag' src="./img/components/tools/spain.webp" alt="Bandera española" />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="sub-menu">
                                <ul className='flags'>
                                    <li>
                                        <span onClick={() => changeLanguage('fr')}>
                                            <img className='flag' src="./img/components/tools/france.webp" alt="Drapeau français" />
                                        </span>
                                    </li>
                                    <li>
                                        <span onClick={() => changeLanguage('en')}>
                                            <img className='flag' src="./img/components/tools/royaume-uni.webp" alt="English flag" />
                                        </span>
                                    </li>
                                    <li>
                                        <span onClick={() => changeLanguage('es')}>
                                            <img className='flag' src="./img/components/tools/spain.webp" alt="Bandera española" />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="reserve" onClick={() => navigateTo('/booking')}>
                <div>
                    <span>
                        {t('reserve')}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Navbar;