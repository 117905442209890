import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Contact from '../components/forms/Contact';
import Footer from '../components/tools/Footer';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';

const Contacts = () => {
    const { t, i18n } = useTranslation(['components\\forms\\formTr']);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='contacts'>
            <div className="img-background">
                <div className="main-title">
                    <span className="title">
                        {t('contact-title')}
                    </span>
                </div>

                <SocialNetworksMainBackground/>
            </div>
            <Contact listenerIsContact={true} />
            <Footer />
        </div>
    );
};

export default Contacts;