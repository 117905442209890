import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import { useSearchParams } from 'react-router-dom';

interface INotif {
    listenerIsContact: boolean
}

const Contact: React.FC<INotif> = ({ listenerIsContact }) => {
    const { t, i18n } = useTranslation(['components\\forms\\formTr']);

    const [isContact, setIsContact] = useState<boolean>(true);
    const [submitBtn, setSubmitBtn] = useState<string>('Envoyer');
    const [sent, setSent] = useState<boolean>(false);
    const [cssBtnDisabled, setCssBtnDisabled] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [begginer, setBegginer] = useState<string>('');
    const [intermediate, setIntermediate] = useState<string>('');
    const [confirmed, setConfirmed] = useState<string>('');
    const [searchParams] = useSearchParams();
    const [subject, setSubject] = useState<string>('');

    const [details, setDetails] = useState<string>("");

    useEffect(() => {
        if (searchParams.get('resa') !== null) {
            let values = searchParams.get('resa')?.split(';');
            if (values !== undefined) {
                setDetails(values[0] + " - " + values[1] + " - " + values[2])
            }
        }
    }, []);

    useEffect(() => {
        const param: string | null = searchParams.get('subject');
        if (param !== null) {
            setSubject(param);
        }

        console.log(i18n)

        setIsContact(listenerIsContact);
        setSubmitBtn(t('contact.form.button'));
        if (t('contact.form.level.begginer') !== undefined) {
            setBegginer(t('contact.form.level.begginer'));
        }
        if (t('contact.form.level.intermediate') !== undefined) {
            setIntermediate(t('contact.form.level.begginer'));
        }
        if (t('contact.form.level.confirmed') !== undefined) {
            setConfirmed(t('contact.form.level.begginer'));
        }
    }, [listenerIsContact, i18n.language]);

    const sendEmail = (event: React.FormEvent<HTMLFormElement>) => {
        setSent(false);
        setError(false);
        event.preventDefault();
        setCssBtnDisabled('btn-blue-disabled');

        if (process.env.REACT_APP_EMAILJS_SERVICE_ID !== undefined
            && process.env.REACT_APP_EMAILJS_TEMPLATE_CONTACT_ID !== undefined
            && process.env.REACT_APP_EMAILJS_TEMPLATE_RESERVATION_ID !== undefined) {

            let service_id: string = process.env.REACT_APP_EMAILJS_SERVICE_ID;
            let template_id = process.env.REACT_APP_EMAILJS_TEMPLATE_RESERVATION_ID;
            if (isContact) {
                template_id = process.env.REACT_APP_EMAILJS_TEMPLATE_CONTACT_ID;
            }

            console.log(template_id)

            emailjs.sendForm(service_id, template_id, event.currentTarget, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
                .then((result) => {
                    console.log(result.text);
                    setSent(true);
                    setCssBtnDisabled('');
                }, (error) => {
                    console.log(error.text);
                    setCssBtnDisabled('');
                    setError(true);
                });

        }

    };

    const [errorDetails, setErrorDetails] = useState<boolean>(false);

    const checkText = (e: any) => {
        let letters = /^[a-zA-Z0-9,;.?:/!"'àéèêâôöëä()\[\]\n_+ €$£-]{0,}$/;
        if (!e.match(letters)) {
            setErrorDetails(true);
            setCssBtnDisabled('btn-blue-disabled');
        } else {
            setErrorDetails(false);
            setCssBtnDisabled('');
        }
        setDetails(e);
    }

    const [cssTextInfos, setCssTextInfos] = useState<string>('none-text-infos');
    const changeTextInfos = () => {
        if (cssTextInfos === '') {
            setCssTextInfos('none-text-infos');
        } else {
            setCssTextInfos('');
        }
    }

    const setSubjectValue = (value: string) => {
        let letters = /^[a-zA-Z0-9,;.?:/!"'àéèêâôöëä()\[\]_+ -]{0,}$/;

        if (value.match(letters)) {
            setSubject(value);
        }
    }

    return (
        <div className="form" id="bookingStart">
            <div className="container">
                <div className="block">
                    <div className="title-global">
                        <p>
                            {t('contact.title')}
                        </p>
                    </div>

                    <div className="description-global">
                        <p>
                            {t('contact.description')}
                        </p>
                    </div>
                    <a href={"tel:" + t('contact.phone')}>
                        <div className="coords">
                            <img src="img/components/tools/phone.svg" alt="Phone" />
                            <p>
                                {t('contact.phone')}
                            </p>
                        </div>
                    </a>
                    <a onClick={() => window.open(process.env.REACT_APP_MAPS)} >
                        <div className="coords">
                            <img src="img/components/tools/map-blue.svg" alt="Maps" />
                            <p>
                                {t('contact.address')}

                            </p>
                        </div>
                    </a>
                    <div className="social-networks">
                        <a onClick={() => window.open(process.env.REACT_APP_INSTAGRAM)} >
                            <img src="img/components/tools/instagram.svg" alt="Instagram" />
                        </a>
                        <a onClick={() => window.open(process.env.REACT_APP_FACEBOOK)} >
                            <img className='notMr30' src="img/components/tools/facebook.svg" alt="Facebook" />
                        </a>
                    </div>
                </div>
                <div className="block">
                    <form onSubmit={sendEmail} className="form">
                        <div className="block-two">
                            <div className="input">
                                <span>
                                    {t('contact.form.name')}
                                </span>
                                <input type="text" required pattern="[a-zA-Zàéèêâôöëä]{2,}" name="lastname"
                                    title={t('errorMsg.alpha')} />
                            </div>
                            <div className="input">
                                <span>
                                    {t('contact.form.firstname')}
                                </span>
                                <input type="text" required pattern="[a-zA-Zàéèêâôöëä]{2,}" name="firstname"
                                    title={t('errorMsg.alpha')} />
                            </div>
                        </div>
                        <div className="block-one">
                            <div className="input">
                                <span>
                                    {t('contact.form.mail')}
                                </span>
                                <input type="text" required
                                    pattern='[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}' name="mail"
                                    title={t('errorMsg.mail')} />
                            </div>
                        </div>
                        <div className="block-two">
                            <div className="input">
                                <span>
                                    {t('contact.form.phone')}
                                </span>
                                <input type="text" name="phone" pattern="[0-9]{10,15}"
                                    title={t('errorMsg.phone')} />
                            </div>

                            {isContact &&
                                <div className="input">
                                    <span>
                                        {t('contact.form.subject')}
                                    </span>
                                    <input type="text" name="subject"
                                        title={t('errorMsg.alphanumeric-spec')}
                                        value={subject}
                                        onChange={(e) => setSubjectValue(e.target.value)} />
                                </div>
                            }

                            {!isContact &&
                                <div className="input">
                                    <span>
                                        {t('contact.form.date')}
                                    </span>
                                    <input required type="date" name="date" />
                                </div>
                            }
                        </div>


                        {!isContact &&
                            <div className="block-two">
                                <div className="input">
                                    <span>
                                        {t('contact.form.level.title')}
                                    </span>
                                    <select name="level" id="">
                                        <option value={begginer} > {t('contact.form.level.begginer')} </option>
                                        <option value={intermediate}>{t('contact.form.level.intermediate')}</option>
                                        <option value={confirmed}>{t('contact.form.level.confirmed')}</option>
                                    </select>
                                </div>
                                <div className="input">
                                    <div className='label-infos'>
                                        <span>
                                            {t('contact.form.number')}
                                        </span>
                                        <div className="btn-infos" onClick={changeTextInfos}>i</div>
                                        <div className={"infos-text " + cssTextInfos}>
                                            {t('errorMsg.nb-persons')}
                                        </div>
                                    </div>
                                    <input type="text" pattern='[0-9]{1,}' name="number"
                                        title={t('errorMsg.numeric')} />
                                </div>
                            </div>
                        }
                        <div className="block-one">
                            <div className="input">
                                <span>
                                    {t('contact.form.details')}
                                </span>
                                
                                <textarea maxLength={40000} onChange={(e) => checkText(e?.target.value)} name="details" 
                                value={details} />
                                
                                {errorDetails &&
                                    <p className='error-msg'>{t('errorMsg.alphanumeric-spec')}</p>
                                }
                            </div>
                        </div>
                        <div className='btn'>
                            {cssBtnDisabled === '' && !errorDetails &&
                                <input className={"btn-blue"} type="submit" value={submitBtn} />
                            }
                            {(cssBtnDisabled !== '' || errorDetails) &&
                                <input className={"btn-blue " + cssBtnDisabled} disabled type="submit" value={submitBtn} />
                            }
                        </div>
                        {error &&
                            <p className='mail-not-sent'><span className="span-red"></span>{t('errorMsg.mail-not-sent')}</p>
                        }
                        {sent &&
                            <p className='mail-sent'><span className="span-green"></span>{t('errorMsg.mail-sent')}</p>
                        }
                    </form>
                </div>
            </div>
        </div >
    );
};

export default Contact;