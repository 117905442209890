import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/tools/Footer';
import SocialNetworksMainBackground from '../components/tools/SocialNetworksMainBackground';

const SurfCamp = () => {
    const { t, i18n } = useTranslation(['pages\\surfCampTr']);
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='camp'>
            <div className="img-background">
                <div className="main-title">
                    <p className="title">
                        {t('title')}
                    </p>
                    <p className="description">
                        {t('description')}
                    </p>
                </div>
                <SocialNetworksMainBackground/>
            </div>
            <div className="background-color">

                <div className="container">

                    <div className="block">
                        <div className="block-img">
                            <img src="img/pages/surfCamp/surf-camp-one.webp" alt="Surf camp" />
                        </div>
                        <div className="block-description">
                            <p className="title-global">
                                {t('surf-camp.title')}
                            </p>
                            <p className="description-global justify">
                                {t('surf-camp.description.part1')}
                            </p>
                            <p className="description-global justify">
                                {t('surf-camp.description.part2')}

                            </p>
                        </div>
                    </div>

                    <div className="block reverse-columns">

                        <div className="block-img">
                            <img src="img/pages/surfCamp/surf-camp-two.webp" alt="Surf camp" />
                        </div>
                        <div className="block-description"><p className="title-global">
                            {t('about')}
                        </p>
                            <p className='description-global'>
                                <img src="img/pages/surfCamp/check.svg" alt="." />
                                <span>{t('things.part1')}</span>
                            </p>
                            <p className='description-global'>
                                <img src="img/pages/surfCamp/check.svg" alt="." />
                                <span>{t('things.part2')}</span>
                            </p>
                            <p className='description-global'>
                                <img src="img/pages/surfCamp/check.svg" alt="." />
                                <span>{t('things.part3')}</span>
                            </p>
                            <p className='description-global'>
                                <img src="img/pages/surfCamp/check.svg" alt="." />
                                <span>{t('things.part4')}</span>
                            </p>
                            <p className='description-global'>
                                <img src="img/pages/surfCamp/check.svg" alt="." />
                                <span>{t('things.part5')}</span>
                            </p>
                        </div>
                    </div>
                    <div className="div-btn">
                        <button onClick={() => navigate('/contacts?subject=[SURF CAMP]')} className="btn-transparent">
                            {t('more')}
                        </button>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    );
};

export default SurfCamp;